import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrashIcon } from '@heroicons/react/24/outline';
import { filter, map, some, isEqual } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import Modal from '@components/Base/Modal';
import { Textarea } from '@components/Base/Textarea';
import { Textbox } from '@components/Base/Textbox';
import ConfirmMessage from '@components/Projects/ConfirmMessage';
import { geographyOptions } from '@containers/projects/constants';
import { classNames } from '@utils/classNames';
import { parseProjectTags } from './helper';
import messages from './messages';
const ProjectForm = ({ isOpen, isLoading, isSuccess, projects, newProject, selectedProject, onCreateProject, onEditProject, onComplete, onClose, }) => {
    const Intl = useIntl();
    const [projectName, setProjectName] = useState('');
    const [geography, setGeography] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [tagList, setTagList] = useState([
        {
            id: 1,
            key: '',
            value: '',
            isAdded: false,
            isKeyDuplicate: false,
            isValueDuplicate: false,
            isPrefilled: false,
        },
    ]);
    const [error, setError] = useState({
        isProjectNameEmpty: false,
        isProjectNameDuplicate: false,
        isGeographyEmpty: false,
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const currentProjectDetails = {
        name: selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.name,
        description: selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.description,
        tags: parseProjectTags((selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.tags) || []),
    };
    const updatedProjectDetails = {
        name: projectName,
        description: projectDescription,
        tags: parseProjectTags(tagList),
    };
    const handleProjectNameChange = (e) => {
        const editedProjectName = e.target.value;
        const isDuplicate = some(projects, ({ name }) => name === editedProjectName);
        setProjectName(editedProjectName);
        setError(Object.assign(Object.assign({}, error), { isProjectNameEmpty: !editedProjectName, isProjectNameDuplicate: isDuplicate }));
    };
    const handleGeographyChange = (e) => {
        setGeography(e);
        setError(Object.assign(Object.assign({}, error), { isGeographyEmpty: !e.value }));
    };
    const handleAddTag = (id) => {
        const updatedTagList = map(tagList, (tag) => {
            if (tag.id === id) {
                tag.isAdded = true;
            }
            return tag;
        });
        const newTagPayload = {
            id: tagList[tagList.length - 1].id + 1,
            key: '',
            value: '',
            isAdded: false,
            isKeyDuplicate: false,
            isValueDuplicate: false,
            isPrefilled: false,
        };
        setTagList([...updatedTagList, newTagPayload]);
    };
    const handleUpdateTagName = (id, newKey) => {
        const isDuplicate = some(tagList, (existingTag) => existingTag.key === newKey);
        const updatedTags = tagList.map((tag) => {
            tag.isKeyDuplicate = false;
            if (tag.id === id) {
                tag.key = newKey;
                tag.isKeyDuplicate = isDuplicate;
            }
            return tag;
        });
        setTagList(updatedTags);
    };
    const handleUpdateTagValue = (id, newValue) => {
        const isDuplicate = some(tagList, (existingTag) => existingTag.value === newValue);
        const updatedTags = tagList.map((tag) => {
            tag.isValueDuplicate = false;
            if (tag.id === id) {
                tag.value = newValue;
                tag.isValueDuplicate = isDuplicate;
            }
            return tag;
        });
        setTagList(updatedTags);
    };
    const handleDeleteTag = (id) => {
        const filteredTags = filter(tagList, (tag) => tag.id !== id);
        setTagList(filteredTags);
    };
    const handleSubmit = () => {
        const updatedTags = map(filter(tagList, ({ key }) => Boolean(key)), ({ id, key, value, isPrefilled }) => isPrefilled ? { id, key, value } : { key, value });
        if (!projectName || !geography) {
            setError(Object.assign(Object.assign({}, error), { isProjectNameEmpty: !projectName, isGeographyEmpty: !geography }));
            return;
        }
        selectedProject
            ? onEditProject({
                name: projectName,
                description: projectDescription,
                tags: updatedTags,
                id: selectedProject.id,
            })
            : onCreateProject({
                name: projectName,
                region: geography.value,
                description: projectDescription,
                tags: updatedTags,
            });
    };
    const handleComplete = () => {
        const payload = selectedProject
            ? Object.assign(Object.assign({}, selectedProject), newProject) : newProject;
        onComplete(payload);
        onClose();
    };
    useEffect(() => {
        if (isEqual(currentProjectDetails, updatedProjectDetails)) {
            setIsButtonDisabled(true);
        }
        else {
            setIsButtonDisabled(false);
        }
    }, [updatedProjectDetails]);
    useEffect(() => {
        if (!selectedProject) {
            return;
        }
        const { name, region, description, tags } = selectedProject;
        name && setProjectName(name);
        const selectedGeography = geographyOptions.find((geography) => geography.value === region);
        selectedGeography && setGeography(selectedGeography);
        description && setProjectDescription(description);
        tags &&
            tags.length &&
            setTagList(map(tags, ({ id, key, value }, index) => ({
                id,
                key,
                value,
                isAdded: tags.length - 1 !== index,
                isKeyDuplicate: false,
                isValueDuplicate: false,
                isPrefilled: true,
            })));
    }, [selectedProject]);
    return isLoading || isSuccess ? (_jsx(ConfirmMessage, { isSuccess: isSuccess, onSuccess: handleComplete, isEditProject: !!selectedProject })) : (_jsx(Modal, { size: "md", show: isOpen, onCancel: onClose, children: _jsxs("div", { className: "relative text-gray-900 w-full h-full overflow-auto px-1 pb-10", children: [_jsx("h3", { className: "text-xl font-semibold leading-6", id: "modal-title", children: selectedProject ? (_jsx(FormattedMessage, Object.assign({}, messages.edit_project))) : (_jsx(FormattedMessage, Object.assign({}, messages.new_project))) }), _jsxs("div", { className: "flex flex-col gap-6 mt-10 w-full h-[60vh] overflow-auto px-[1px]", children: [_jsxs("div", { className: "w-full flex gap-3", children: [_jsx("div", { className: "flex-1", children: _jsx(Textbox, { name: "projectName", maxLength: 30, label: Intl.formatMessage(messages.project_name), required: true, error: error.isProjectNameDuplicate
                                            ? Intl.formatMessage(messages.project_name_error)
                                            : error.isProjectNameEmpty
                                                ? Intl.formatMessage(messages.mandatory_field_error)
                                                : '', className: "inline-block flex-1", placeholder: Intl.formatMessage(messages.project_name_placeholder), value: projectName, onChange: handleProjectNameChange }) }), _jsxs("div", { className: "flex-1", children: [_jsxs("label", { htmlFor: "geography", className: "text-black text-sm font-normal inline-block mb-1", children: [_jsx(FormattedMessage, Object.assign({}, messages.geography)), _jsx("span", { className: "pl-0.5", children: "*" })] }), _jsx(Dropdown, { id: "geography", value: geography, isDisabled: !!(selectedProject && selectedProject.region), isSearchable: false, onChange: (e) => handleGeographyChange(e), options: geographyOptions }), error.isGeographyEmpty && (_jsx("span", { className: "mt-0 text-xs text-red-700", children: Intl.formatMessage(messages.mandatory_field_error) }))] })] }), _jsx(Textarea, { maxLength: 100, value: projectDescription, onChange: (e) => setProjectDescription(e.target.value), placeholder: "Describe your project", label: Intl.formatMessage(messages.description_placeholder), name: "projectDescription", className: "resize-none" }), _jsxs("div", { className: "flex items-center text-black gap-2", children: [_jsx("p", { className: "text-sm", children: _jsx(FormattedMessage, Object.assign({}, messages.tags)) }), _jsx("div", { className: "h-[1px] w-full bg-gray-200" })] }), _jsx("div", { className: "pb-4 flex flex-col gap-3", children: map(tagList, ({ id, key, value, isAdded, isKeyDuplicate, isValueDuplicate, isPrefilled, }, index) => (_jsxs("div", { className: "flex items-start gap-3", children: [_jsx("div", { className: "flex-1", children: _jsx(Textbox, { "data-testid": `tag-name-input${index}`, error: isKeyDuplicate && !selectedProject
                                                ? Intl.formatMessage(messages.project_tag_error)
                                                : '', label: !index ? Intl.formatMessage(messages.tag_name) : '', onChange: (e) => handleUpdateTagName(id, e.target.value), placeholder: "Case insensitive", value: key, className: classNames('block', isPrefilled && 'pointer-events-none') }) }), _jsxs("div", { className: "flex-1 flex items-center gap-3", children: [_jsx("div", { className: "flex-1", children: _jsx(Textbox, { "data-testid": `tag-value-input${index}`, error: isValueDuplicate && selectedProject
                                                        ? Intl.formatMessage(messages.project_tag_error)
                                                        : '', label: !index ? Intl.formatMessage(messages.tag_value) : '', onChange: (e) => handleUpdateTagValue(id, e.target.value), placeholder: "Case sensitive", className: "block", value: value }) }), isAdded ? (_jsx(Button, { "data-testid": "remove-tag-btn", onClick: () => handleDeleteTag(id), variant: "destructive_ghost", className: "self-end mb-1", children: _jsx(TrashIcon, { className: "w-4 h-4" }) })) : (_jsx(Button, { "data-testid": "add-tag-btn", onClick: () => handleAddTag(id), variant: "ghost", disabled: !key || !value || isKeyDuplicate || isValueDuplicate, className: classNames('mb-1', isKeyDuplicate || isValueDuplicate
                                                    ? 'self-start'
                                                    : 'self-end'), children: _jsx(FontAwesomeIcon, { icon: faPlus }) }))] })] }, id))) })] }), _jsx(Button, { "data-testid": "submit-btn", className: "absolute bottom-0 left-0", onClick: handleSubmit, disabled: isButtonDisabled, children: selectedProject ? (_jsx(FormattedMessage, Object.assign({}, messages.save_changes))) : (_jsx(FormattedMessage, Object.assign({}, messages.create_new_project))) })] }) }));
};
export default ProjectForm;
