export const ProctorPermission = {
    Flows: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Events: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Proctors: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Schedule: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
};
