import { defineMessages } from 'react-intl';
import { commonScope } from '@utils/messages';
export const scope = 'app.component.configure_card';
export default defineMessages({
    configure: {
        id: `${scope}.configure`,
        defaultMessage: 'Configure',
    },
    custom_branding_label: {
        id: `${scope}.custom_branding_label`,
        defaultMessage: 'Custom Branding',
    },
    custom_branding_description: {
        id: `${scope}.custom_branding_description`,
        defaultMessage: 'Customize the look of Proview to fit your needs.',
    },
    logo: {
        id: `${commonScope}.logo`,
        defaultMessage: 'Logo',
    },
    uploaded: {
        id: `${commonScope}.uploaded`,
        defaultMessage: 'Uploaded',
    },
    not_uploaded: {
        id: `${commonScope}.not_uploaded`,
        defaultMessage: 'Not Uploaded',
    },
    disabled: {
        id: `${commonScope}.disabled`,
        defaultMessage: 'Disabled',
    },
    enabled: {
        id: `${commonScope}.enabled`,
        defaultMessage: 'Enabled',
    },
    color: {
        id: `${scope}.custom_branding.color`,
        defaultMessage: 'Primary Color',
    },
    offboarding_label: {
        id: `${scope}.offboarding_label`,
        defaultMessage: 'Offboarding',
    },
    offboarding_description: {
        id: `${scope}.offboarding_description`,
        defaultMessage: 'Actions you want the attendee to perform after the session ends.',
    },
    feedback: {
        id: `${scope}.offboarding.feedback`,
        defaultMessage: 'Feedback',
    },
    feedback_description: {
        id: `${scope}.offboarding.feedback_description`,
        defaultMessage: 'Activating this will prompt the attendees to submit their feedback post session.',
    },
    setting_stakes_label: {
        id: `${scope}.setting_stakes_label`,
        defaultMessage: 'Setting Stakes',
    },
    setting_stakes_description: {
        id: `${scope}.setting_stakes_description`,
        defaultMessage: 'Stakes represents the significance of the session being monitored.',
    },
    no_stakes: {
        id: `${scope}.no_stakes`,
        defaultMessage: 'No Stakes selected.',
    },
    browser_config: {
        id: `${scope}.browser_config`,
        defaultMessage: 'Browser Configurations',
    },
    camera_recordings: {
        id: `${scope}.camera_recordings`,
        defaultMessage: 'Camera & Recordings',
    },
    ab_label: {
        id: `${scope}.ab_label`,
        defaultMessage: 'Attendee Onboarding',
    },
    ab_description: {
        id: `${scope}.ab_description`,
        defaultMessage: 'Customize the onboarding process of attendees to fit your session needs.',
    },
    ab_description_panel: {
        id: `${scope}.ab_description_panel`,
        defaultMessage: 'Toggle the attendee permissions.',
    },
    screen_sharing: {
        id: `${scope}.attendee_onboarding.screen_sharing`,
        defaultMessage: 'Screen Sharing',
    },
    primary_camera_recording: {
        id: `${scope}.attendee_onboarding.primary_camera_recording`,
        defaultMessage: 'Primary camera recording',
    },
    screen_sharing_recording: {
        id: `${scope}.attendee_onboarding.screen_sharing_recording`,
        defaultMessage: 'Screen sharing recording',
    },
    identity_permission: {
        id: `${scope}.attendee_onboarding.identity_permission`,
        defaultMessage: 'Identity Verification Permission',
    },
    environment_test: {
        id: `${scope}.attendee_onboarding.environment_test`,
        defaultMessage: 'Environment Test',
    },
    photo_id_capture: {
        id: `${scope}.attendee_onboarding.photo_id_capture`,
        defaultMessage: 'Photo ID Capture',
    },
    face_capture: {
        id: `${scope}.attendee_onboarding.face_capture`,
        defaultMessage: 'Face Capture',
    },
});
