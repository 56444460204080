import { defineMessages } from 'react-intl';
export const scope = 'app.containers.layout';
export default defineMessages({
    changes_saved: {
        id: `${scope}.changes_saved`,
        defaultMessage: 'Changes saved',
    },
    changes_summary: {
        id: `${scope}.changes_summary`,
        defaultMessage: 'The changes have been saved successfully.',
    },
});
