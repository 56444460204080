import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FunnelIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import { classNames } from '@utils/classNames';
import messages from '../messages';
export const FilterButton = ({ hasAppliedFilters, onClick, }) => {
    return (_jsxs(Button, { type: "button", role: "button", onClick: onClick, variant: "outline", "data-testid": "filter-button", className: classNames('text-gray-900 border-gray-200', {
            'relative border-blue-700': hasAppliedFilters,
        }), children: [_jsxs("div", { className: "flex gap-[0.25rem] items-center", children: [_jsx(FunnelIcon, { className: "h-[0.85rem] stroke-[2.5px]" }), _jsx(FormattedMessage, Object.assign({}, messages.filters_button))] }), hasAppliedFilters && (_jsx("div", { className: "h-2 w-2 bg-blue-700 rounded absolute top-[-2px] right-[-2px]" }))] }));
};
