import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import classNames from 'classnames';
const Tabs = (props) => {
    const { tabData, onSelect, selectedValue } = props;
    const [activeTab, setActiveTab] = useState(selectedValue);
    const handleSelect = (newSelectedTab) => {
        setActiveTab(newSelectedTab);
        onSelect(newSelectedTab);
    };
    return (_jsx("div", { className: "w-fit p-[2px] flex bg-gray-100 rounded-lg font-roboto items-center h-[38px] cursor-pointer gap-[2px]", children: tabData
            .filter((tab) => tab.isShow)
            .map((tab, index) => (_jsx("button", { onClick: () => handleSelect(tab.value), className: classNames(tab.value === activeTab
                ? 'bg-white border-zinc-200 rounded-md text-gray-900 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]'
                : 'bg-transparent border-transparent text-gray-700', 'rounded-md px-[25px] py-[8px] text-center text-sm border font-normal h-[34px]', 'transition-all duration-300 ease-out transform'), children: tab.label }, index))) }));
};
export default Tabs;
