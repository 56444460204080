import { createSlice } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { transformConfigToPayload, transformToVersionLog, } from '@containers/flowDetails/helper';
import { STATUS, TOAST_STATE } from '@utils/data/enums';
export const initialState = {
    isLoading: false,
    error: null,
    data: null,
    updateFlowStatus: { loadingKey: '', error: null },
    updateFlowConfigStatus: {
        isPublishedLoading: false,
        isConfigUpdateLoading: false,
        error: null,
    },
    versionLog: [],
    createFlowConfigStatus: {
        isLoading: false,
        error: null,
        isSuccess: false,
    },
    triggerToast: '',
};
export const flowConfigSlice = createSlice({
    name: 'flowConfig',
    initialState: initialState,
    reducers: {
        getFlowConfigByIDAction: (state, _) => {
            state.isLoading = true;
            state.error = null;
        },
        getFlowConfigByIDSuccessAction: (state, { payload }) => {
            const updatedFlow = payload;
            state.isLoading = false;
            state.data = Object.assign(Object.assign({}, updatedFlow), { flow_configs: [
                    transformConfigToPayload(updatedFlow.flow_configs[updatedFlow.flow_configs.length - 1]),
                ] });
        },
        getFlowConfigByIDErrorAction: (state, { payload: error }) => {
            state.isLoading = false;
            state.error = error;
        },
        updateFlowAction: (state, _) => {
            state.updateFlowStatus.loadingKey = Object.keys(_.payload)[0];
            state.updateFlowStatus.error = null;
        },
        updateFlowSuccessAction: (state, { payload }) => {
            state.updateFlowStatus.loadingKey = '';
            state.data = Object.assign(Object.assign({}, state.data), payload);
            if (state.triggerToast === TOAST_STATE.UPDATE_FLOW_NAME_INITIATED) {
                state.triggerToast = TOAST_STATE.UPDATE_FLOW_NAME_SUCCESS;
            }
            if (state.triggerToast === TOAST_STATE.DEFAULT_FLOW_CHANGE_INITIATED) {
                state.triggerToast = TOAST_STATE.DEFAULT_FLOW_CHANGED;
            }
        },
        updateFlowErrorAction: (state, { payload: error }) => {
            state.updateFlowStatus.loadingKey = '';
            state.updateFlowStatus.error = error;
            if (state.triggerToast === TOAST_STATE.UPDATE_FLOW_NAME_INITIATED) {
                state.triggerToast = TOAST_STATE.UPDATE_FLOW_NAME_FAILED;
            }
            if (state.triggerToast === TOAST_STATE.DEFAULT_FLOW_CHANGE_INITIATED) {
                state.triggerToast = TOAST_STATE.DEFAULT_FLOW_UPDATE_FAILED;
            }
        },
        updateFlowConfigAction: (state, action) => {
            const { payload } = action.payload;
            if (find(Object.keys(payload), (key) => key === 'status')) {
                state.updateFlowConfigStatus.isPublishedLoading = true;
            }
            else {
                state.updateFlowConfigStatus.isConfigUpdateLoading = true;
            }
            state.updateFlowStatus.error = null;
        },
        updateFlowConfigSuccessAction: (state, { payload }) => {
            state.updateFlowConfigStatus.isPublishedLoading = false;
            state.updateFlowConfigStatus.isConfigUpdateLoading = false;
            state.data = Object.assign(Object.assign({}, state.data), { flow_configs: [transformConfigToPayload(payload)] });
            if (payload.status === STATUS.PUBLISHED) {
                state.triggerToast = TOAST_STATE.PUBLISH_FLOW;
            }
            else {
                state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_SAVED;
            }
        },
        updateFlowConfigErrorAction: (state, { payload: error }) => {
            state.updateFlowConfigStatus.isPublishedLoading = false;
            state.updateFlowConfigStatus.isConfigUpdateLoading = false;
            state.updateFlowConfigStatus.error = error;
            state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_FAILED;
        },
        setVersionLogChanges: (state, { payload }) => {
            const versionLog = transformToVersionLog(payload);
            state.versionLog = versionLog;
        },
        createFlowConfigAction: (state, action) => {
            const { payload, flowUuid } = action.payload;
            state.createFlowConfigStatus.isLoading = true;
            state.createFlowConfigStatus.isSuccess = false;
            state.createFlowConfigStatus.error = null;
            void payload;
            void flowUuid;
        },
        createFlowConfigSuccessAction: (state) => {
            state.createFlowConfigStatus.isLoading = false;
            state.createFlowConfigStatus.isSuccess = true;
            state.createFlowConfigStatus.error = null;
            state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_SAVED;
        },
        createFlowConfigErrorAction: (state, { payload: error }) => {
            state.createFlowConfigStatus.isLoading = false;
            state.createFlowConfigStatus.isSuccess = false;
            state.createFlowConfigStatus.error = error;
            state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_FAILED;
        },
        resetTriggerToast: (state, { payload }) => {
            state.triggerToast = payload;
        },
    },
});
export const { getFlowConfigByIDAction, getFlowConfigByIDSuccessAction, getFlowConfigByIDErrorAction, updateFlowAction, updateFlowSuccessAction, updateFlowErrorAction, updateFlowConfigAction, updateFlowConfigSuccessAction, updateFlowConfigErrorAction, setVersionLogChanges, createFlowConfigAction, createFlowConfigSuccessAction, createFlowConfigErrorAction, resetTriggerToast, } = flowConfigSlice.actions;
export default flowConfigSlice.reducer;
