import { defineMessages } from 'react-intl';
import { commonScope } from '@utils/messages';
export const scope = 'app.containers.flow_details';
export default defineMessages({
    upload_logo: {
        id: `${scope}.upload_logo`,
        defaultMessage: 'Upload Logo',
    },
    save_changes: {
        id: `${scope}.save_changes`,
        defaultMessage: 'Save Changes',
    },
    primary_color: {
        id: `${scope}.primary_color`,
        defaultMessage: 'Primary Color',
    },
    logo: {
        id: `${commonScope}.logo`,
        defaultMessage: 'Logo',
    },
    logo_description: {
        id: `${scope}.logo_description`,
        defaultMessage: "For best results, upload a logo that's saved as",
    },
    logo_description_l1: {
        id: `${scope}.logo_description_l1`,
        defaultMessage: 'PNG or JPEG',
    },
    logo_description_l2: {
        id: `${scope}.logo_description_l2`,
        defaultMessage: '50KB or smaller',
    },
    logo_description_l3: {
        id: `${scope}.logo_description_l3`,
        defaultMessage: 'Resolution of at least 200x300 pixels',
    },
    browser_config_description: {
        id: `${scope}.browser_config_description`,
        defaultMessage: 'Set Secure Browser and other supported browsers.',
    },
    enable_all: {
        id: `${commonScope}.enable_all`,
        defaultMessage: 'Enable All',
    },
    web: {
        id: `${commonScope}.web`,
        defaultMessage: 'Web',
    },
    mobile: {
        id: `${commonScope}.mobile`,
        defaultMessage: 'Mobile',
    },
    sb_description: {
        id: `${scope}.browser_config.sb_description`,
        defaultMessage: 'Delivers remote assessments securely by preventing candidates from switching tabs and opening other applications.',
    },
    supported_browsers: {
        id: `${scope}.browser_config.supported_browsers`,
        defaultMessage: 'Supported Browsers',
    },
    supported_browsers_description: {
        id: `${scope}.browser_config.supported_browsers_description`,
        defaultMessage: 'Choose the supported browsers you will permit the attendee to take the proctored session from.',
    },
    kiosk_description: {
        id: `${scope}.browser_config.kiosk_description`,
        defaultMessage: "This lockdown mode ensures that during the assessment, individuals are unable to access other browsers, applications, or system settings that could be used to compromise the exam's integrity.",
    },
    blacklist_apps: {
        id: `${scope}.browser_config.blacklist_apps`,
        defaultMessage: 'Blacklist Apps',
    },
    blacklist_apps_description: {
        id: `${scope}.browser_config.blacklist_apps_description`,
        defaultMessage: 'These apps will be prohibited for use by the attendee only if kiosk mode is enabled.',
    },
    screen_recording: {
        id: `${scope}.screen_recording`,
        defaultMessage: 'Screen Recording',
    },
    screen_recording_description: {
        id: `${scope}.screen_recording_description`,
        defaultMessage: "Activating this will record the attendee's screen events for later playback.",
    },
    camera_recordings_description: {
        id: `${scope}.camera_recordings.description`,
        defaultMessage: 'Control screen, primary camera recording & specify secondary camera setup.',
    },
    screen_share_description: {
        id: `${scope}.camera_recordings.screen_share_description`,
        defaultMessage: 'Activating this will prompt the attendee to allow sharing of the entire screen during the session.',
    },
    primary_camera_recording_description: {
        id: `${scope}.camera_recordings.primary_camera_recording_description`,
        defaultMessage: "Activating this will record the attendee's primary camera for later playback.",
    },
    secondary_camera_description: {
        id: `${scope}.camera_recordings.secondary_camera_description`,
        defaultMessage: 'Prompts the attendee to set up a device for secondary camera.',
    },
    screen_share: {
        id: `${scope}.camera_recordings.screen_share`,
        defaultMessage: 'Screen Share',
    },
    secondary_camera: {
        id: `${scope}.camera_recordings.secondary_camera`,
        defaultMessage: 'Secondary Camera Set-Up',
    },
    secondary_camera_recording: {
        id: `${scope}.camera_recordings.secondary_camera_recording`,
        defaultMessage: 'Secondary Camera Recording',
    },
    secondary_camera_recording_description: {
        id: `${scope}.camera_recordings.secondary_camera_recording_description`,
        defaultMessage: "Activating this will record the attendee's secondary camera for later playback.",
    },
    environment_test_description: {
        id: `${scope}.environment_test_description`,
        defaultMessage: 'Prompts the attendee to show their surroundings.',
    },
    identity_verification: {
        id: `${scope}.identity_verification`,
        defaultMessage: 'Identity Verification',
    },
    identity_verification_description: {
        id: `${scope}.identity_verification_description`,
        defaultMessage: 'Configure the methods to verify the identity of the test taker.',
    },
    face_capture_description: {
        id: `${scope}.identity_verification.face_capture_description`,
        defaultMessage: 'Activating this will prompt the attendee to take a picture of their face.',
    },
    face_capture_verify: {
        id: `${scope}.identity_verification.face_capture_verify`,
        defaultMessage: 'Compare With Source Image',
    },
    face_capture_verify_description: {
        id: `${scope}.identity_verification.face_capture_verify_description`,
        defaultMessage: "Activating this will allow the proctor to compare the attendee's image captured along with a source image.",
    },
    photo_id_description: {
        id: `${scope}.identity_verification.photo_id_description`,
        defaultMessage: 'Activating this will prompt the attendee to hold their photo ID card in front of the camera and then click on Capture button.',
    },
    photo_id_capture_verify_description: {
        id: `${scope}.identity_verification.photo_id_capture_verify_description`,
        defaultMessage: "Activating this will mean photo ID will be verified against the attendee's captured image.",
    },
    version_history: {
        id: `${scope}.version_history`,
        defaultMessage: 'Version History',
    },
    version_history_description: {
        id: `${scope}.version_history_description`,
        defaultMessage: 'List of Published versions & the changes.',
    },
    version_history_unpublished: {
        id: `${scope}.version_history.unpublished`,
        defaultMessage: 'Unpublished',
    },
    version_history_current: {
        id: `${scope}.version_history.current`,
        defaultMessage: 'Current',
    },
    published_on: {
        id: `${scope}.published_on`,
        defaultMessage: 'Published on ',
    },
    updated_on: {
        id: `${scope}.updated_on`,
        defaultMessage: 'Updated on ',
    },
    blacklisted_apps_for: {
        id: `${scope}.blacklisted_apps_for`,
        defaultMessage: 'Blacklisted apps for ',
    },
});
export const generateChangeLogMessages = (key) => {
    return {
        id: `${scope}.${key}`,
        defaultMessage: `${key}`,
    };
};
