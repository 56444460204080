export const Coordinator = {
    Flows: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Proctors: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Schedule: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Settings: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Payments: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
};
