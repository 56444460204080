var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Select, { components, } from 'react-select';
import { isTextTruncated } from '@utils/helpers';
const formatOptionLabels = ({ label, disabled, details, }) => {
    const labelStyle = disabled ? 'text-gray-500' : 'text-gray-900';
    return (_jsxs("div", { className: "text-sm", children: [_jsx("div", { className: `font-normal truncate ${labelStyle}`, children: label }), details && (_jsx("div", { children: _jsxs("div", { className: "text-xs text-gray-500", children: [details.id, " | ", details.location] }) }))] }));
};
export const reactSelectDefaultStyles = {
    placeholder: (provided) => (Object.assign(Object.assign({}, provided), { paddingLeft: '6px', cursor: 'pointer' })),
    menu: (provided) => (Object.assign(Object.assign({}, provided), { zIndex: 99 })),
    option: (provided, state) => (Object.assign(Object.assign({}, provided), { backgroundColor: state.isSelected
            ? '#F3F4F6'
            : state.isFocused
                ? '#F3F4F6'
                : 'white', color: '#111827', '&:hover': {
            backgroundColor: '#F3F4F6',
        } })),
    group: (provided) => (Object.assign(Object.assign({}, provided), { paddingTop: 0, paddingBottom: 0, cursor: 'pointer' })),
    control: (props) => (Object.assign(Object.assign({}, props), { boxShadow: 'none', fontSize: '0.875rem', cursor: 'pointer' })),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided) => (Object.assign(Object.assign({}, provided), { color: '#6B7280', ':hover': {
            color: '#6B7280',
            cursor: 'pointer',
        } })),
    groupHeading: (provided) => (Object.assign(Object.assign({}, provided), { '&:before': {
            content: "''",
            display: 'block',
            paddingTop: '8px',
            borderTop: '1px solid #E5E7EB',
            cursor: 'pointer',
        } })),
};
function GroupDropdown({ options, onChange, selectedValue, }) {
    const [selectedOption, setSelectedOption] = useState();
    const [isTruncated, setIsTruncated] = useState(false);
    const labelRef = useRef(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const toggleDropdown = () => {
        setMenuIsOpen(!menuIsOpen);
    };
    useEffect(() => {
        if (labelRef.current) {
            setIsTruncated(isTextTruncated(labelRef.current));
        }
    }, [selectedOption]);
    useEffect(() => {
        selectedValue && setSelectedOption(selectedValue);
    }, [selectedValue]);
    const handleChange = (selectedOption) => {
        onChange(selectedOption);
        !selectedOption.clickOnly && setSelectedOption(selectedOption);
    };
    const ValueContainer = (_a) => {
        var { children } = _a, props = __rest(_a, ["children"]);
        const { hasValue } = props;
        if (!hasValue) {
            return _jsx(_Fragment, { children: children });
        }
        return (_jsxs("div", { className: "flex-1 p-2 px-3", children: [_jsx("div", { className: "text-xs text-gray-500", children: "CURRENT PROJECT" }), _jsx("div", { className: "text-sm text-gray-900 truncate w-60", title: isTruncated && selectedOption ? selectedOption.label : undefined, ref: labelRef, children: selectedOption && selectedOption.label })] }));
    };
    const Placeholder = (props) => {
        return (_jsxs(components.Placeholder, Object.assign({}, props, { children: [_jsx("div", { className: "p-1 pt-3 text-xs text-gray-500 ", children: "CURRENT PROJECT" }), _jsx("div", { className: "pb-1 pl-1 text-sm text-gray-900", children: "-" })] })));
    };
    return (_jsx("div", { onClick: toggleDropdown, onKeyDown: toggleDropdown, tabIndex: 0, className: "cursor-pointer", role: "button", "aria-label": "Toggle Dropdown", children: _jsx(Select, { isSearchable: false, value: selectedOption, options: options, menuIsOpen: menuIsOpen, components: {
                ValueContainer,
                Placeholder,
            }, formatOptionLabel: formatOptionLabels, styles: Object.assign({}, reactSelectDefaultStyles), placeholder: "CURRENT PROJECT", classNames: {
                control: ({ isFocused }) => classNames('border rounded !shadow-0 !bg-white', isFocused && '!border-gray-200'),
            }, menuPlacement: "auto", onChange: (value) => handleChange(value) }) }));
}
export default GroupDropdown;
