import { createSlice } from '@reduxjs/toolkit';
import { TOAST_STATE } from '@utils/data/enums';
import { currentProjectsInitialState, newProjectsInitialState, } from 'app/__mocks__/data';
export const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
    data: [],
    currentProject: currentProjectsInitialState,
    projectForm: {
        isLoading: false,
        error: null,
        projectId: '',
        newProject: newProjectsInitialState,
        isSuccess: false,
        isComplete: false,
        isOpenModal: false,
    },
    triggerToast: null,
};
export const projectsSlice = createSlice({
    name: 'projects',
    initialState: initialState,
    reducers: {
        getProjectsAction: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getProjectSuccessAction: (state, { payload }) => {
            state.data = payload;
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
        },
        getProjectErrorAction: (state, { payload: error }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = error;
        },
        setCurrentProject: (state, { payload }) => {
            state.currentProject = Object.assign({}, payload);
        },
        createProjectAction: (state, { payload: _payload }) => {
            state.projectForm.isLoading = true;
            state.projectForm.isSuccess = false;
            state.projectForm.error = null;
        },
        editProjectAction: (state, { payload: _payload }) => {
            state.projectForm.isLoading = true;
            state.projectForm.isSuccess = false;
            state.projectForm.error = null;
        },
        projectFormSuccessAction: (state, { payload }) => {
            state.projectForm.isLoading = false;
            state.projectForm.isSuccess = true;
            state.projectForm.newProject = payload;
            state.triggerToast = TOAST_STATE.PROJECT_DETAILS_SAVED;
        },
        projectFormCompleteAction: (state, _action) => {
            state.projectForm.isComplete = true;
            state.projectForm.isSuccess = false;
            state.projectForm.isLoading = false;
            state.data = state.data.map((project) => {
                if (project.id === state.projectForm.newProject.id) {
                    return Object.assign(Object.assign({}, project), state.projectForm.newProject);
                }
                return project;
            });
            state.projectForm.newProject = newProjectsInitialState;
        },
        projectFormErrorAction: (state, { payload: error }) => {
            state.projectForm.isLoading = false;
            state.projectForm.error = error;
            state.triggerToast = TOAST_STATE.PROJECT_DETAILS_SAVE_FAILED;
        },
        setProjectFormModal: (state, { payload }) => {
            if (payload.isOpen) {
                state.projectForm.projectId = payload.projectId || '';
                state.projectForm.isOpenModal = payload.isOpen;
            }
            else {
                state.projectForm.projectId = '';
                state.projectForm.isOpenModal = false;
            }
        },
        resetTriggerToast: (state) => {
            state.triggerToast = null;
        },
    },
});
export const { getProjectsAction, getProjectSuccessAction, getProjectErrorAction, setCurrentProject, createProjectAction, editProjectAction, projectFormSuccessAction, projectFormCompleteAction, projectFormErrorAction, setProjectFormModal, resetTriggerToast, } = projectsSlice.actions;
export const selectProjects = (state) => state.projects.data;
export const selectProjectById = (state, id) => state.projects.data.find((project) => project.id === id);
export default projectsSlice.reducer;
