import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Popover, Transition, PopoverButton, PopoverPanel, } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import { classNames } from '@utils/classNames';
import Channels from 'app/images/svgs/channels.svg';
import messages from './messages';
const SettingsNavItem = () => {
    return (_jsxs(Popover, { className: "relative", children: [_jsx(PopoverButton, { className: "w-full outline-none", children: _jsx(FormattedMessage, Object.assign({}, messages.settings)) }), _jsx(Transition, { as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-100", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95", children: _jsx(PopoverPanel, { className: classNames('absolute bg-white bottom-[-16px] z-50 left-36'), children: _jsx("div", { className: "border border-gray-200 w-52 rounded-md shadow-[0px_4px_24px_rgba(17,24,39,0.08)]", children: _jsx("div", { className: "pt-4 pb-3 px-4", children: _jsxs("div", { className: "text-sm gap-2 flex align-middle", children: [_jsx("img", { src: Channels, alt: "Flow", className: "min-w-6 min-h-6" }), _jsx(FormattedMessage, Object.assign({}, messages.integrations))] }) }) }) }) })] }));
};
export default SettingsNavItem;
